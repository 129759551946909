import { createContext } from 'react';

const CommonContext = createContext();

export default CommonContext;

export const AppNavPaths = {
    ActiveEmployees: '/activeEmployees',
    AdminTool: '/adminTool',
    AssignmentConfirmation: '/confirm',
    AssignmentCalendar: '/assignmentCalendar',
    Billing: '/billing',
    BillingChargeCategory: '/billingChargeCategory',
    BillingCharge: '/billingCharge',
    BillingRate: '/billingRate',
    BillingIssues: '/billingIssues',
    CancelConfirmation: '/cancelConfirm',
    ChargeView: '/chargeView',
    ChargeType: '/chargeType',
    ChargeTypeNew: '/newChargeType',
    ChargeTypes: '/chargeTypes',
    ChargeTypeUnits: '/chargeTypeUnit',
    ChargeTypeGroup: '/chargeTypeGroup',
    ChargeTypeGroupNew: '/newChargeTypeGroup',
    ChargeTypeGroups: '/chargeTypeGroups',
    Companies: '/companies',
    CollectedReporting: '/collectedReporting',
    Company: '/company',
    CompanyTypes: '/companyType',
    CompanyBillingGroup: '/companyBillingGroup',
    CompanyBillingGroupNew: '/companyBillingGroupNew',
    PaySchedule: '/paySchedule',
    PayScheduleNew: '/payScheduleNew',
    ComplianceType: '/complianceType',
    ComplianceTypeNew: '/complianceType?new=true',
    Contacts: '/contacts',
    Contract: '/contract',
    ContactActivities: '/contactActivities',
    ContractNew: '/newContract',
    Contracts: '/contracts',
    ContractStatuses: '/contractStatus',
    ContractTemplate: '/contractTemplate',
    ContractTemplateIndex: '/contractTemplates',
    ContractTemplateNew: '/newContractTemplate',
    DailyAttendanceEvents: '/dailyAttendanceEvents',
    EmployeeDailyRoster: '/roster',
    Dispatch: '/dispatch',
    Employee: '/employee',
    EmployeeCompliances: '/employeeCompliances',
    LongTermStatuses: '/longTermStatuses',
    EmployeeNew: '/newEmployee',
    EmployeeNewHire: '/newEmployee?newHire=true',
    EmployeeAccountStatuses: '/employeeAccountStatus',
    Employees: '/employees',
    EmployeeTimesheets: '/myTimesheets',
    Equipment: '/equipment',
    EquipmentCompliances: '/equipmentCompliances',
    EquipmentStatuses: '/equipmentStatuses',
    EquipmentTypes: '/equipmentTypes',
    EquipmentTypeStatuses: '/equipmentTypeStatuses',
    Group: '/group',
    Groups: '/groups',
    GroupNew: '/newGroup',
    Invoice: '/invoice',
    InvoiceGeneration: '/invoiceGeneration',
    NotFound: '/notFound',
    NoPermission: '/noPermission',
    Unauthorized: '/unauthorized',
    UserGroup: '/userGroup',
    UserGroups: '/userGroups',
    UserGroupNew: '/newUserGroup',
    Role: '/role',
    Roles: '/roles',
    RoleNew: '/newRole',
    Permission: '/permission',
    Permissions: '/permissions',
    PermissionNew: '/newPermission',
    Holidays: '/holidays',
    Job: '/job',
    JobNew: '/newJob',
    JobView: '/jobView',
    Jobs: '/jobs',
    JobAssignmentCancellationTypes: '/jobAssignmentCancellationTypes',
    Organization: '/organization',
    OrganizationNew: '/newOrganization',
    Organizations: '/organizations',
    LocationTypes: '/locationTypes',
    Payroll: '/payroll',
    PayrollIndividual: '/payrollIndividual',
    QuickBooksInventoryItems: '/quickBooksInventoryItems',
    QuickBooksInventoryItem: '/quickBooksInventoryItem',
    QuickBooksInventoryItemNew: '/quickBooksInventoryItem/new',
    SelfDispatchTimesheet: '/sdtimesheet',
    SelfDispatchTimesheets: '/sdtimesheets',
    SelfDispatchTimesheetNew: '/sdtimesheetNew',
    NonFlaggingTimesheet: '/nonFlagTimesheet',
    NonFlaggingTimesheetNew: '/nonFlagTimesheetNew',
    ServerError: '/servererror',
    PayrollManagement: '/payrollManagement',
    Tenant: '/tenant',
    Tenants: '/tenants',
    TimeCharges: '/timecharges',
    TimeOffRequests: '/timeoff',
    Timesheet: '/timesheet',
    TimesheetManagement: '/timesheetManagement',
    TimeSheetDashboard: '/timesheetDashboard',
    USCountyGroups: '/countyGroups',
    USCountyGroupNew: '/countyGroup/new',
    USCountyGroup: '/countyGroup',
};

export const YesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

const prefix = '/api/v1';
export const ApiRoutes = {
    activeEmployees: {
        query: () => `${prefix}/activeEmployees/query`,
        export: () => `${prefix}/activeEmployees/export`,
    },
    address: {
        create: () => `${prefix}/address`,
        update: (addressId) => `${prefix}/address/${addressId}`,
    },
    adminTool: {
        search: () => `${prefix}/admintools/search`,
        getAdminToolHistory: (id) => `${prefix}/admintoolHistory/getAdminToolHistory/${id}`,
        moveTimesheetToJob: () => `${prefix}/admintool/moveTimesheetToJob`,
        swapContractChargeType: () => `${prefix}/admintool/swapContractChargeType`,
        syncTimesheetContractBilling: () => `${prefix}/admintool/syncTimesheetContractBilling`,
        addJobCity: () => `${prefix}/admintool/addJobCity`,
        addChargeTypeToTimesheetEquipment: () => `${prefix}/admintool/addChargeTypeToTimesheetEquipment`,
        rejectBillingPayroll: () => `${prefix}/admintool/rejectBillingPayroll`,
        removeCharges: () => `${prefix}/admintool/removeCharges`,
    },
    auth: {
        permissions: () => `${prefix}/authorization/permissions`,
        profile: () => `${prefix}/authorization/profile`,
        roles: () => `${prefix}/authorization/roles`,
        groups: () => `${prefix}/authorization/groups`,
        confirmPhone: (code) => `${prefix}/authorization/confirmPhone/${code}`,
    },
    billing: {
        search: () => `${prefix}/billings/search`,
        excelExport: () => `${prefix}/billings/excelExport`,
        export: () => `${prefix}/billings/export`,
        approve: () => `${prefix}/billings/approve`,
        reject: () => `${prefix}/billings/reject`,
        adminReject: () => `${prefix}/billings/adminReject`,
        needsAdminReject: (id) => `${prefix}/billings/needsAdminReject/${id}`,
        download: (id) => `${prefix}/billing/download/${id}`,
        setEmergency: () => `${prefix}/billing/setEmergency`,
    },
    billingChargeCategory: {
        edit: () => `${prefix}/billingChargeCategory/edit`,
        search: () => `${prefix}/billingChargeCategories/search`,
        excelExport: () => `${prefix}/billingChargeCategories/excelExport`,
        export: () => `${prefix}/billingChargeCategories/export`,
        approve: () => `${prefix}/billingChargeCategories/approve`,
        reject: () => `${prefix}/billingChargeCategories/reject`,
        process: () => `${prefix}/billingChargeCategories/process`,
        download: (id) => `${prefix}/billingChargeCategory/download/${id}`,
        setEmergency: () => `${prefix}/billingChargeCategory/setEmergency`,
        slideout: (billingId) => `${prefix}/billingChargeCategory/${billingId}/slideout`,
        billperiods: (billingId) => `${prefix}/billingChargeCategory/${billingId}/billperiods`,
    },
    billingCharge: {
        add: () => `${prefix}/billingCharge/add`,
        edit: () => `${prefix}/billingCharge/edit`,
        search: () => `${prefix}/billingCharges/search`,
        excelExport: () => `${prefix}/billingCharges/excelExport`,
        export: () => `${prefix}/billingCharges/export`,
        approve: () => `${prefix}/billingCharges/approve`,
        reject: () => `${prefix}/billingCharges/reject`,
        adminReject: () => `${prefix}/billingCharges/adminReject`,
        needsAdminReject: (id) => `${prefix}/billingCharges/needsAdminReject/${id}`,
        process: () => `${prefix}/billingCharges/process`,
        download: (id) => `${prefix}/billingCharge/download/${id}`,
        setEmergency: () => `${prefix}/billingCharge/setEmergency`,
        slideout: (billingId) => `${prefix}/billingCharge/${billingId}/slideout`,
        unapprove: (billingDetailId) => `${prefix}/billingCharge/unapprove/${billingDetailId}`,
        void: () => `${prefix}/billingCharge/void`,
        unvoid: (billingId) => `${prefix}/billingCharge/unvoid/${billingId}`,
    },
    billingRate: {
        search: () => `${prefix}/billingRates/search`,
        getGridPermissions: () => `${prefix}/billingRates/getGridPermissions`,
        excelExport: () => `${prefix}/billingRates/excelExport`,
        chargeTypeBillingRateHistory: (chargeTypeId) => `${prefix}/billingRates/chargeTypeBillingRateHistory/${chargeTypeId}`,
        companyBillingRateHistory: () => `${prefix}/billingRates/companyBillingRateHistory`,
    },
    billingIssues: {
        search: () => `${prefix}/billingIssues/search`,
        reject: () => `${prefix}/billingIssues/reject`,
        invoiceReject: () => `${prefix}/billingIssues/invoiceReject`,
        accept: () => `${prefix}/billingIssues/accept`,
        invoiceAccept: () => `${prefix}/billingIssues/invoiceAccept`,
        reply: () => `${prefix}/billingIssues/reply`,
        messages: (billingId) => `${prefix}/billingIssues/${billingId}`,
        invoiceMessages: () => `${prefix}/billingIssues/invoiceMessages`,
        excelExport: () => `${prefix}/billingIssues/excelExport`,
    },
    calendar: {
        get: (start, end) => `${prefix}/calendar/${start}/${end}`,
    },
    chargeView: {
        search: () => `${prefix}/chargeViews/search`,
        excelExport: () => `${prefix}/chargeViews/excelExport`,
        hoursReportCompanies: () => `${prefix}/chargeViews/hoursReportCompanies`,
        hoursReportExport: () => `${prefix}/chargeViews/hoursReportExport`,
    },
    chargeType: {
        all: () => `${prefix}/chargeTypes`,
        search: () => `${prefix}/chargeTypes/search`,
        byCustomer: (customerId) => `${prefix}/chargeTypes/customer${customerId ? `/${customerId}` : ''
        }`,
        byCustomerBillable: (customerId) => `${prefix}/chargeTypes/customerBillable${customerId ? `/${customerId}` : ''
        }`,
        byId: (chargeTypeId) => `${prefix}/chargeType/${chargeTypeId}`,
        create: () => `${prefix}/chargeType`,
        update: (chargeTypeId) => `${prefix}/chargeType/${chargeTypeId}`,
        delete: (chargeTypeId) => `${prefix}/chargeType/${chargeTypeId}`,
        byContract: (id) => `${prefix}/chargeTypes/contract/${id}`,
        bySetupTypes: () => `${prefix}/chargeTypes/bySetupTypes`,
        setupSurcharges: () => `${prefix}/chargeTypes/setupSurcharges`,
        byTimesheetNumber: (timesheetNum) => `${prefix}/chargeTypes/byTimesheet/${timesheetNum}`,
        byTimesheetId: (timesheetId) => `${prefix}/chargeTypes/byTimesheetId/${timesheetId}`,
        byContractId: (contractId) => `${prefix}/chargeTypes/byContractId/${contractId}`,
    },
    chargeTypeStatus: {
        all: () => `${prefix}/chargeTypeStatuses`,
        search: () => `${prefix}/chargeTypeStatuses`,
        byId: (chargeTypeStatusId) => `${prefix}/chargeTypeStatus/${chargeTypeStatusId}`,
        create: () => `${prefix}/chargeTypeStatus`,
        update: (chargeTypeStatusId) => `${prefix}/chargeTypeStatus/${chargeTypeStatusId}`,
        delete: (chargeTypeStatusId) => `${prefix}/chargeTypeStatus/${chargeTypeStatusId}`,
    },
    chargeTypeUnit: {
        all: () => `${prefix}/chargeTypeUnits`,
        search: () => `${prefix}/chargeTypeUnits/search`,
        byId: (chargeTypeUnitId) => `${prefix}/chargeTypeUnit/${chargeTypeUnitId}`,
        create: () => `${prefix}/chargeTypeUnit`,
        update: (chargeTypeUnitId) => `${prefix}/chargeTypeUnit/${chargeTypeUnitId}`,
        delete: (chargeTypeUnitId) => `${prefix}/chargeTypeUnit/${chargeTypeUnitId}`,
    },
    chargeTypeGroup: {
        search: () => `${prefix}/chargeTypeGroups/search`,
        byId: (chargeTypeGroupId) => `${prefix}/chargeTypeGroup/${chargeTypeGroupId}`,
        create: () => `${prefix}/chargeTypeGroup`,
        update: (chargeTypeGroupId) => `${prefix}/chargeTypeGroup/${chargeTypeGroupId}`,
        delete: (chargeTypeGroupId) => `${prefix}/chargeTypeGroup/${chargeTypeGroupId}`,
        byContract: (id) => `${prefix}/chargeTypeGroups/contract/${id}`,
    },
    company: {
        all: () => `${prefix}/companies`,
        create: () => `${prefix}/company`,
        invoicing: () => `${prefix}/companies/invoicing`,
        byId: (companyId) => `${prefix}/company/${companyId}`,
        locations: (companyId) => `${prefix}/company/${companyId}/locations`,
        search: () => `${prefix}/companies/search`,
        excelExport: () => `${prefix}/companies/excelExport`,
        types: () => `${prefix}/company/types`,
        customerCompanies: (companyId) => `${prefix}/companies/customers/${companyId}`,
        customerOperationsCentersCompanies: (customerCompanyId) => `${prefix}/companies/customerOperationCenters/${customerCompanyId}`,
        payrollCompanies: () => `${prefix}/companies/payroll`,
        dispatchCompanies: () => `${prefix}/companies/dispatch`,
        dispatchCompaniesByCompanyId: (companyId) => `${prefix}/companies/dispatch/${companyId}`,
        foremen: (companyId) => `${prefix}/company/${companyId}/foremen`,
        contacts: (companyId) => `${prefix}/company/${companyId}/contacts`,
        allowAlternativePayroll: (companyId) => `${prefix}/company/${companyId}/allowAlternativePayroll`,
        operationsCentersApply: (companyId) => `${prefix}/company/${companyId}/operationsCentersApply`,
        defaultTerms: (companyId) => `${prefix}/company/defaultTerms/${companyId}`,
        defaultMapping: (companyId) => `${prefix}/company/defaultMapping/${companyId}`,
        defaultChargeTypeGroups: (companyId) => `${prefix}/companies/defaultChargeTypeGroups/${companyId}`,
    },
    companyBillingGroups: {
        search: () => `${prefix}/companies/searchBillingGroups`,
        byId: (billingGroupId) => `${prefix}/company/billingGroup/${billingGroupId}`,
        update: (billingGroupId) => `${prefix}/company/billingGroup/${billingGroupId}`,
    },
    companyType: {
        search: () => `${prefix}/companyTypes/search`,
        byId: (companyTypeId) => `${prefix}/companyType/${companyTypeId}`,
        create: () => `${prefix}/companyType`,
        update: (companyTypeId) => `${prefix}/companyType/${companyTypeId}`,
        delete: (companyTypeId) => `${prefix}/companyType/${companyTypeId}`,
    },
    complianceTypes: {
        all: () => `${prefix}/complianceTypes`,
        allEquipmentComplianceTypes: () => `${prefix}/complianceTypes/getComplianceTypesForEquipment`,
        allEmployeeComplianceTypes: () => `${prefix}/complianceTypes/getComplianceTypesForEmployees`,
        forAllDispatches: () => `${prefix}/complianceTypes/forAllDispatches`,
        search: () => `${prefix}/complianceTypes/search`,
        byId: (complianceTypeId) => `${prefix}/complianceType/${complianceTypeId}`,
        create: () => `${prefix}/complianceType/create`,
        update: () => `${prefix}/complianceType/update`,
        delete: (complianceTypeId) => `${prefix}/complianceType/${complianceTypeId}`,
    },
    contactActivity: {
        byId: (contactActivityId) => `${prefix}/contactActivity/${contactActivityId}`,
        update: (contactActivityId) => `${prefix}/contactActivity/${contactActivityId}`,
        create: () => `${prefix}/contactActivity`,
        search: () => `${prefix}/contactActivities/search`,
        delete: (contactActivityId) => `${prefix}/contactActivity/${contactActivityId}`,
        excelExport: () => `${prefix}/contactActivities/excelExport`,
    },
    contract: {
        byId: (contractId) => `${prefix}/contract/${contractId}`,
        filteredRates: (contractId, dateStr) => `${prefix}/contract/${contractId}/${dateStr}`,
        update: (contractId) => `${prefix}/contract/${contractId}`,
        create: () => `${prefix}/contract`,
        search: () => `${prefix}/contracts/search`,
        searchExport: () => `${prefix}/contracts/searchExport`,
        getGridPermissions: () => `${prefix}/contracts/getGridPermissions`,
        userHasSelfDispatching: () => `${prefix}/contracts/hasSelfDispatching`,
        getNonFlagging: () => `${prefix}/contracts/getNonFlagging`,
        getSelfDispatching: () => `${prefix}/contracts/selfDispatching`,
        moveTimesheets: () => `${prefix}/contract/moveTimesheets`,
    },
    contractCompliances: {
        byId: (contractComplianceId) => `${prefix}/contractCompliance/${contractComplianceId}`,
        create: () => `${prefix}/contractCompliance`,
        update: (contractComplianceId) => `${prefix}/contractCompliance/${contractComplianceId}`,
        delete: (contractComplianceId) => `${prefix}/contractCompliance/${contractComplianceId}`,
        allForContract: (contractId) => `${prefix}/contractCompliances/getCompliancesForContract/${contractId}`,
    },
    contractTemplate: {
        create: () => `${prefix}/contractTemplate`,
        byId: (id) => `${prefix}/contractTemplate/${id}`,
        search: () => `${prefix}/contractTemplates/search`,
        names: () => `${prefix}/contractTemplates`,
    },
    contractTemplateCompliances: {
        byId: (contractTemplateComplianceId) => `${prefix}/contractTemplateCompliance/${contractTemplateComplianceId}`,
        create: () => `${prefix}/contractTemplateCompliance`,
        update: (contractTemplateComplianceId) => `${prefix}/contractTemplateCompliance/${contractTemplateComplianceId}`,
        delete: (contractTemplateComplianceId) => `${prefix}/contractTemplateCompliance/${contractTemplateComplianceId}`,
        allForContractTemplate: (contractTemplateId) => `${prefix}/contractTemplateCompliances/getCompliancesForContractTemplate/${contractTemplateId}`,
    },
    contractStatus: {
        search: () => `${prefix}/contractStatuses/search`,
        byId: (contractStatusId) => `${prefix}/contractStatus/${contractStatusId}`,
        create: () => `${prefix}/contractStatus`,
        update: (contractStatusId) => `${prefix}/contractStatus/${contractStatusId}`,
        delete: (contractStatusId) => `${prefix}/contractStatus/${contractStatusId}`,
    },
    customers: {
        all: (companyId) => `${prefix}/customers/${companyId}`,
    },
    dailyAttendanceEvent: {
        all: () => `${prefix}/dailyAttendanceEvents`,
        byId: (id) => `${prefix}/dailyAttendanceEvent/${id}`,
        create: () => `${prefix}/dailyAttendanceEvent/create`,
        update: () => `${prefix}/dailyAttendanceEvent/update`,
        search: () => `${prefix}/dailyAttendanceEvents/search`,
        delete: (id) => `${prefix}/dailyAttendanceEvent/${id}`,
    },
    dispatch: {
        viewModel: () => `${prefix}/dispatch/viewmodel`,
        staff: () => `${prefix}/dispatch/staff`,
        equipment: () => `${prefix}/dispatch/equipment`,
        crews: () => `${prefix}/dispatch/crews`,
        jobs: () => `${prefix}/dispatch/jobs`,
        cancelledJobs: () => `${prefix}/dispatch/cancelledJobs`,
        saveAssignment: () => `${prefix}/dispatch/saveAssignment`,
        removeAssignment: (jobAssignmentId) => `${prefix}/dispatch/removeAssignment/${jobAssignmentId}`,
        removeAllAssignments: () => `${prefix}/dispatch/removeAllAssignments`,
        cancelAssignment: () => `${prefix}/dispatch/cancelAssignment`,
        cancelJob: () => `${prefix}/dispatch/cancelJob`,
        setCrewLead: (jobAssignmentId) => `${prefix}/dispatch/crewLead/${jobAssignmentId}`,
        setEquipmentAssignedEmployee: (jobAssignmentId, employeeId) => `${prefix}/dispatch/setEquipmentAssignedEmployee/${jobAssignmentId}/${employeeId}`,
        setEquipmentAssignedEquipment: (jobAssignmentId, equipmentId) => `${prefix}/dispatch/setEquipmentAssignedEquipment/${jobAssignmentId}/${equipmentId}`,
    },

    employee: {
        toggleImpersonation: () => `${prefix}/employee/toggleImpersonation`,
        cancelImpersonation: () => `${prefix}/employee/cancelImpersonation`,
        suggestUsername: (name) => `${prefix}/employee/suggestUsername/${name}`,
        byId: (empId) => `${prefix}/employee/${empId}`,
        create: () => `${prefix}/employee`,
        update: (empId) => `${prefix}/employee/${empId}`,
        payRate: {
            create: (empId) => `${prefix}/employee/${empId}/payRate`,
            update: (empId, payRateId) => `${prefix}/employee/${empId}/payRate/${payRateId}`,
        },
        groups: {
            all: () => `${prefix}/employees`,
        },
        rosterView: (empId) => `${prefix}/employee/${empId}/roster`,
    },
    employees: {
        all: () => `${prefix}/employees`,
        associatedWithContactActivity: () => `${prefix}/employees/associatedWithContactActivity`,
        byCompany: (companyId) => `${prefix}/employees/${companyId}`,
        byDispatchLocationAndEquipmentType: (
            dispatchLocationId,
            equipmentTypeId,
        ) => `${prefix}/employees/byDispatchLocationAndEquipmentType/${dispatchLocationId}/${equipmentTypeId}`,
        search: () => `${prefix}/employees/search`,
        roster: () => `${prefix}/employees/roster`,
        rosterExport: () => `${prefix}/employees/rosterExport`,
        excelExport: () => `${prefix}/employees/excelExport`,
    },
    employeeAccountStatus: {
        byId: (employeeAccountStatusId) => `${prefix}/employeeAccountStatus/${employeeAccountStatusId}`,
        create: () => `${prefix}/employeeAccountStatus`,
        update: (employeeAccountStatusId) => `${prefix}/employeeAccountStatus/${employeeAccountStatusId}`,
        delete: (employeeAccountStatusId) => `${prefix}/employeeAccountStatus/${employeeAccountStatusId}`,
    },
    employeeAccountStatuses: {
        search: () => `${prefix}/employeeAccountStatuses/search`,
        all: () => `${prefix}/employeeAccountStatuses`,
    },
    employeeCompliances: {
        byId: (applicationUserComplianceId) => `${prefix}/applicationUserCompliance/${applicationUserComplianceId}`,
        create: () => `${prefix}/applicationUserCompliance`,
        update: (applicationUserComplianceId) => `${prefix}/applicationUserCompliance/${applicationUserComplianceId}`,
        delete: (applicationUserComplianceId) => `${prefix}/applicationUserCompliance/${applicationUserComplianceId}`,
        allForEmployee: (employeeId) => `${prefix}/applicationUserCompliances/getCompliancesForEmployee/${employeeId}`,
        search: () => `${prefix}/applicationUserCompliances/search/`,
    },
    employeeDailyAttendanceEvent: {
        byId: (id) => `${prefix}/employeeDailyAttendanceEvent/${id}`,
        upsert: () => `${prefix}/employeeDailyAttendanceEvent/upsert`,
        delete: () => `${prefix}/employeeDailyAttendanceEvent/delete`,
        allFor: () => `${prefix}/employeeDailyAttendanceEvents/allFor`,
    },
    employeeLongTermStatus: {
        allForEmployee: (employeeId) => `${prefix}/employeeLongTermStatuses/${employeeId}`,
        byId: (id) => `${prefix}/employeeLongTermStatus/${id}`,
        create: () => `${prefix}/employeeLongTermStatus/create`,
        update: () => `${prefix}/employeeLongTermStatus/update`,
        delete: (id) => `${prefix}/employeeLongTermStatus/${id}`,
        allEffectiveBetween: () => `${prefix}/employeeLongTermStatuses/allEffectiveBetween`,
    },
    employeeHistory: {
        byId: (empId) => `${prefix}/employeeHistory/${empId}`,
    },
    employeeGroupHistory: {
        byId: (empId) => `${prefix}/employeeGroupHistory/${empId}`,
    },
    employeeLocationHistory: {
        byId: (empId) => `${prefix}/employeeLocationHistory/${empId}`,
    },
    employeeLongTermStatusHistory: {
        byId: (empId) => `${prefix}/employeeLongTermStatusHistory/${empId}`,
    },
    employeePayRateHistory: {
        byId: (empId) => `${prefix}/employeePayRateHistory/${empId}`,
    },
    employeeRestrictionHistory: {
        byId: (empId) => `${prefix}/employeeRestrictionHistory/${empId}`,
    },
    equipment: {
        all: () => `${prefix}/equipments`,
        search: () => `${prefix}/equipments/search`,
        excelExport: () => `${prefix}/equipments/excelExport`,
        byId: (equipmentId) => `${prefix}/equipment/${equipmentId}`,
        byDispatchLocation: (companyId) => `${prefix}/equipments/company/${companyId}`,
        byType: (typeId) => `${prefix}/equipments/${typeId}`,
        create: () => `${prefix}/equipment`,
        update: (equipmentId) => `${prefix}/equipment/${equipmentId}`,
        delete: (equipmentId) => `${prefix}/equipment/${equipmentId}`,
    },
    equipmentCompliances: {
        byId: (equipmentComplianceId) => `${prefix}/equipmentCompliance/${equipmentComplianceId}`,
        create: () => `${prefix}/equipmentCompliance`,
        update: (equipmentComplianceId) => `${prefix}/equipmentCompliance/${equipmentComplianceId}`,
        delete: (equipmentComplianceId) => `${prefix}/equipmentCompliance/${equipmentComplianceId}`,
        allForEquipment: (equipmentId) => `${prefix}/equipmentCompliances/getCompliancesFor/${equipmentId}`,
        search: () => `${prefix}/equipmentCompliances/search/`,
    },
    equipmentType: {
        all: () => `${prefix}/equipmentTypes`,
        search: () => `${prefix}/equipmentTypes/search`,
        byId: (equipmentTypeId) => `${prefix}/equipmentType/${equipmentTypeId}`,
        create: () => `${prefix}/equipmentType`,
        update: (equipmentTypeId) => `${prefix}/equipmentType/${equipmentTypeId}`,
        delete: (equipmentTypeId) => `${prefix}/equipmentType/${equipmentTypeId}`,
    },
    equipmentStatus: {
        all: () => `${prefix}/equipmentStatuses`,
        search: () => `${prefix}/equipmentStatuses/search`,
        byId: (equipmentStatusId) => `${prefix}/equipmentStatus/${equipmentStatusId}`,
        create: () => `${prefix}/equipmentStatus`,
        update: (equipmentStatusId) => `${prefix}/equipmentStatus/${equipmentStatusId}`,
        delete: (equipmentStatusId) => `${prefix}/equipmentStatus/${equipmentStatusId}`,
    },
    equipmentTypeStatus: {
        all: () => `${prefix}/equipmentTypeStatuses`,
        search: () => `${prefix}/equipmentTypeStatuses/search`,
        byId: (equipmentTypeId) => `${prefix}/equipmentTypeStatus/${equipmentTypeId}`,
        create: () => `${prefix}/equipmentTypeStatus`,
        update: (equipmentTypeId) => `${prefix}/equipmentTypeStatus/${equipmentTypeId}`,
        delete: (equipmentTypeId) => `${prefix}/equipmentTypeStatus/${equipmentTypeId}`,
    },
    group: {
        all: () => `${prefix}/groups`,
        search: () => `${prefix}/groups/search`,
        byId: (groupId) => `${prefix}/group/${groupId}`,
        create: () => `${prefix}/group`,
        update: (groupId) => `${prefix}/group/${groupId}`,
        delete: (groupId) => `${prefix}/group/${groupId}`,
    },
    invoice: {
        streamCTRFile: (invoiceId) => `${prefix}/invoice/streamCTRFile/${invoiceId}`,
        streamProofOf40File: (invoiceId) => `${prefix}/invoice/streamProofOf40File/${invoiceId}`,
        streamInvoice: (invoiceId) => `${prefix}/invoice/streamInvoice/${invoiceId}`,
        streamInvoiceTimesheets: (invoiceId) => `${prefix}/invoice/streamInvoiceTimesheets/${invoiceId}`,
        draft: (invoiceId) => `${prefix}/invoice/draft/${invoiceId}`,
        draftTimesheetDelete: () => `${prefix}/invoice/draftTimesheetDelete`,
        approve: (invoiceId) => `${prefix}/invoice/approve/${invoiceId}`,
        revert: (invoiceId) => `${prefix}/invoice/revert/${invoiceId}`,
        create: () => `${prefix}/invoice`,
        update: (invoiceId) => `${prefix}/invoice/${invoiceId}`,
        restart: (invoiceId) => `${prefix}/invoice/restart/${invoiceId}`,
        delete: (invoiceId) => `${prefix}/invoice/${invoiceId}`,
        byId: (invoiceId) => `${prefix}/invoice/${invoiceId}`,
        search: () => `${prefix}/invoices/search`,
        timesheets: (contractId, workorderNumber, weekDate) => `${prefix}/invoices/timesheets/${contractId}/${workorderNumber}/${weekDate}`,
        ctrexport: (id) => `${prefix}/invoice/ctrexport/${id}`,
        proofof40: (id) => `${prefix}/invoice/proofof40/${id}`,
        downloadInvoiceTimesheets: (id) => `${prefix}/invoice/downloadInvoiceTimesheets/${id}`,
        emailInvoice: (id) => `${prefix}/invoice/emailInvoice/${id}`,
        downloadInvoice: (id) => `${prefix}/invoice/downloadInvoice/${id}`,
        streamInvoices: (ids) => `${prefix}/invoices/streamInvoices/${ids}`,
        exportToNetSuite: () => `${prefix}/invoices/exporttonetsuite`,
        exportToQuickbooks: () => `${prefix}/invoices/exporttoquickbooks`,
        clearExport: () => `${prefix}/invoices/clearexport`,
        downloadInvoices: () => `${prefix}/invoices/downloadinvoices`,
        getExportFile: (id, type) => `${prefix}/invoices/getexportfile/${id}/${type}`,
        getCMExportFiles: () => `${prefix}/invoices/getcreditmemofiles`,
        invoiceExportsToClear: (id) => `${prefix}/invoices/invoiceexportstoclear/${id}`,
        excelExport: () => `${prefix}/invoices/excelExport`,
        slideoutStaff: (dispLocationId) => `${prefix}/invoice/slideoutStaff/${dispLocationId}`,
        slideoutEquipment: (dispLocationId) => `${prefix}/invoice/slideoutEquipment/${dispLocationId}`,
    },
    invoiceGeneration: {
        search: () => `${prefix}/invoiceGenerations/search`,
        excelExport: () => `${prefix}/invoiceGenerations/excelExport`,
        export: () => `${prefix}/invoiceGenerations/export`,
        reject: () => `${prefix}/invoiceGenerations/reject`,
        billingReject: () => `${prefix}/invoiceGenerations/billingReject`,
        adminReject: () => `${prefix}/invoiceGenerations/adminReject`,
        needsAdminReject: (id) => `${prefix}/invoiceGenerations/needsAdminReject/${id}`,
        download: (id) => `${prefix}/invoiceGeneration/download/${id}`,
        setEmergency: () => `${prefix}/invoiceGeneration/setEmergency`,
        setOnHold: () => `${prefix}/invoiceGeneration/setOnHold`,
        removeOnHold: () => `${prefix}/invoiceGeneration/removeOnHold`,
        generateChargeReport: () => `${prefix}/invoiceGenerations/generateChargeReport`,
    },
    localization: {
        strings: () => `${prefix}/localization/strings`,
    },
    locationContact: {
        all: () => `${prefix}/locationContacts`,
        byId: (id) => `${prefix}/locationContact/${id}`,
        create: () => `${prefix}/locationContact`,
        update: (id) => `${prefix}/locationContact/${id}`,
        search: () => `${prefix}/locationContacts/search`,
        forJob: (jobId) => `${prefix}/locationContacts/forJob/${jobId}`,
        excelExport: () => `${prefix}/locationContacts/excelExport`,
    },
    longTermStatus: {
        all: () => `${prefix}/longTermStatuses`,
        byId: (id) => `${prefix}/longTermStatus/${id}`,
        create: () => `${prefix}/longTermStatus/create`,
        update: () => `${prefix}/longTermStatus/update`,
        search: () => `${prefix}/longTermStatuses/search`,
        delete: (id) => `${prefix}/longTermStatus/${id}`,
    },
    userGroup: {
        all: () => `${prefix}/userGroups`,
        search: () => `${prefix}/userGroups/search`,
        byId: (userGroupId) => `${prefix}/userGroup/${userGroupId}`,
        create: () => `${prefix}/userGroup`,
        update: (userGroupId) => `${prefix}/userGroup/${userGroupId}`,
        delete: (userGroupId) => `${prefix}/userGroup/${userGroupId}`,
    },
    permission: {
        all: () => `${prefix}/permissions`,
        search: () => `${prefix}/permissions/search`,
        byId: (permissionId) => `${prefix}/permission/${permissionId}`,
        create: () => `${prefix}/permission`,
        update: (permissionId) => `${prefix}/permission/${permissionId}`,
        delete: (permissionId) => `${prefix}/permission/${permissionId}`,
    },
    job: {
        weeks: () => `${prefix}/jobs/weeks`,
        search: () => `${prefix}/jobs/search`,
        dailyView: () => `${prefix}/jobs/dailyView`,
        dailyViewHistory: () => `${prefix}/jobs/dailyViewHistory`,
        dailyViewExcelExport: () => `${prefix}/jobs/dailyViewExcelExport`,
        dailyViewHistoryExcelExport: () => `${prefix}/jobs/dailyViewHistoryExcelExport`,
        byId: (jobId) => `${prefix}/job/${jobId}`,
        byContractId: (contractId) => `${prefix}/job/${contractId}`,
        getResources: (contractId) => `${prefix}/job/getJobResources/${contractId}`,
        getNonResources: (contractId) => `${prefix}/job/getJobNonResources/${contractId}`,
        create: () => `${prefix}/job`,
        update: (jobId) => `${prefix}/job/${jobId}`,
        delete: (jobId) => `${prefix}/job/${jobId}`,
        postAttachments: (jobId) => `${prefix}/job/${jobId}/attach`,
        attachment: (jobId) => `${prefix}/job/${jobId}/attachment`,
        getAllAttachments: (jobId) => `${prefix}/job/${jobId}/attachments`,
        getTimesheetStatus: (jobId) => `${prefix}/timesheetStatus/${jobId}`,
    },
    jobAssignment: {
        byId: (assignmentId) => `${prefix}/jobAssignment/${assignmentId}`,
        confirm: (assignmentId) => `${prefix}/jobAssignment/confirm/${assignmentId}`,
        notify: () => `${prefix}/jobAssignments/notify`,
        geofences: () => `${prefix}/jobAssignments/geofences`,
        setEmergency: () => `${prefix}/jobAssignments/setEmergency`,
        eventLog: () => `${prefix}/jobAssignments/eventLog`,
    },
    jobAssignmentCancellation: {
        byId: (assignmentCancellationId) => `${prefix}/jobAssignmentCancellation/${assignmentCancellationId}`,
        cancelConfirmed: (assignmentCancellationId) => `${prefix}/jobAssignmentCancellation/cancelConfirmed/${assignmentCancellationId}`,
    },
    jobAssignmentCancellationType: {
        byId: (id) => `${prefix}/jobAssignmentCancellationType/${id}`,
        all: () => `${prefix}/jobAssignmentCancellationTypes/all`,
        search: () => `${prefix}/jobAssignmentCancellationTypes/search`,
        create: () => `${prefix}/jobAssignmentCancellationType/create`,
        update: () => `${prefix}/jobAssignmentCancellationType/update`,
        delete: (id) => `${prefix}/jobAssignmentCancellationType/${id}`,
    },
    locationContactType: {
        all: () => `${prefix}/locationContactTypes`,
    },
    locationType: {
        search: () => `${prefix}/locationTypes/search`,
        byId: (locationTypeId) => `${prefix}/locationType/${locationTypeId}`,
        create: () => `${prefix}/locationType`,
        update: (locationTypeId) => `${prefix}/locationType/${locationTypeId}`,
        delete: (locationTypeId) => `${prefix}/locationType/${locationTypeId}`,
    },
    paySchedule: {
        search: () => `${prefix}/payrollManagements/searchPaySchedules`,
        all: () => `${prefix}/payrollManagements/paySchedules`,
        byId: (payScheduleId) => `${prefix}/payrollManagements/paySchedule/${payScheduleId}`,
        update: (payScheduleId) => `${prefix}/payrollManagements/paySchedule/${payScheduleId}`,
    },
    payroll: {
        search: () => `${prefix}/payrolls/search`,
        searchindividual: () => `${prefix}/payrolls/searchindividual`,
        searchfullview: () => `${prefix}/payrolls/searchfullview`,
        excelExport: () => `${prefix}/payrolls/excelExport`,
        excelExportIndividual: () => `${prefix}/payrolls/excelExportIndividual`,
        approve: () => `${prefix}/payrolls/approve`,
        export: () => `${prefix}/payrolls/export`,
        payrollReject: () => `${prefix}/payrolls/payrollReject`,
        excelWeeklyExport: () => `${prefix}/payrolls/excelWeeklyExport`,
        excelHoursExport: () => `${prefix}/payrolls/excelHoursExport`,
        employees: () => `${prefix}/payrolls/employees`,
        download: (id) => `${prefix}/payroll/download/${id}`,
        downloadWeekly: (id) => `${prefix}/payroll/downloadWeekly/${id}`,
        edit: () => `${prefix}/payroll/edit`,
        doesPayrollHaveEdits: () => `${prefix}/payroll/doesPayrollHaveEdits`,
        doesPayrollHaveExports: () => `${prefix}/payroll/doesPayrollHaveExports`,
    },
    payrollManagement: {
        search: () => `${prefix}/payrollManagements/search`,
        excelExport: () => `${prefix}/payrollManagements/excelExport`,
        slideout: (payrollId) => `${prefix}/payrollManagement/${payrollId}/slideout`,
        add: () => `${prefix}/payrollManagement/add`,
        edit: () => `${prefix}/payrollManagement/edit`,
        approve: () => `${prefix}/payrollManagements/approve`,
        process: () => `${prefix}/payrollManagements/process`,
        adminReject: () => `${prefix}/payrollManagements/adminReject`,
        needsAdminReject: (id) => `${prefix}/payrollManagements/needsAdminReject/${id}`,
        reject: () => `${prefix}/payrollManagements/reject`,
        download: (id) => `${prefix}/payrollManagement/download/${id}`,
        downloadWeekly: (id) => `${prefix}/payrollManagement/downloadWeekly/${id}`,
        setEmergency: () => `${prefix}/payrollManagement/setEmergency`,
        recalculateWeek: () => `${prefix}/payrollManagement/recalculateWeek`,
    },
    quickbooks: {
        search: () => `${prefix}/quickBooksInventoryItems/search`,
        create: () => `${prefix}/quickBooksInventoryItem/new`,
        update: (qbiId) => `${prefix}/quickBooksInventoryItem/${qbiId}`,
        edit: (qbiId) => `${prefix}/quickBooksInventoryItem/${qbiId}`,
        excelExport: () => `${prefix}/quickBooksInventoryItems/excelExport`,
    },
    report: {
        timesheet: (timesheetId) => `${prefix}/report/timesheet/${timesheetId}`,
        timesheetBilling: (timesheetId) => `${prefix}/report/timesheet/${timesheetId}/billing`,
        timesheetPayroll: (timesheetId) => `${prefix}/report/timesheet/${timesheetId}/payroll`,
        timesheetPayrollIndividual: (timesheetId) => `${prefix}/report/timesheet/${timesheetId}/payrollIndividual`,
        invoice: (invoiceId) => `${prefix}/report/invoice/${invoiceId}`,
        newCustomerBillableHours: () => `${prefix}/report/newCustomerBillableHours`,
        staffing: () => `${prefix}/report/staffing`,
        expiringContract: () => `${prefix}/report/expiringContract`,
    },
    role: {
        all: () => `${prefix}/roles`,
        search: () => `${prefix}/roles/search`,
        byId: (roleId) => `${prefix}/role/${roleId}`,
        create: () => `${prefix}/role`,
        update: (roleId) => `${prefix}/role/${roleId}`,
        delete: (roleId) => `${prefix}/role/${roleId}`,
    },
    selfDispatchTimesheet: {
        byId: (id) => `${prefix}/selfDispatchTimesheet/${id}`,
        equipmentByChargeType: (chargeTypeId) => `${prefix}/selfDispatchTimesheet/equipment/${chargeTypeId}`,
        create: () => `${prefix}/selfDispatchTimesheet`,
        selfDispatchNotifications: () => `${prefix}/selfDispatchTimesheet/selfDispatchNotifications`,
        nonFlagNotifications: () => `${prefix}/selfDispatchTimesheet/nonFlagNotifications`,
        count: () => `${prefix}/selfDispatchTimesheet/count`,
    },
    tenant: {
        all: () => `${prefix}/tenants/all`,
        byId: (companyId) => `${prefix}/tenant/${companyId}`,
        search: () => `${prefix}/tenants/search`,
        settings: () => `${prefix}/tenant/settings`,
        viewModel: () => `${prefix}/tenants/viewModel`,
        invalidPhoneNumbers: () => `${prefix}/tenant/invalidphonenumbers`,
        optionalCompanyTypes: () => `${prefix}/tenant/optionalCompanyTypes`,
        countyCaptureTypes: () => `${prefix}/tenant/countyCaptureTypes`,
        getLogo: () => `${prefix}/tenant/logo`,
        updateBrandLogo: (tenantId) => `${prefix}/tenant/${tenantId}/updateBrandLogo`,
        updateReportLogo: (tenantId) => `${prefix}/tenant/${tenantId}/updateReportLogo`,
    },
    timesheet: {
        overview: (timesheetId) => `${prefix}/timesheet/${timesheetId}/overview`,
        slideout: (timesheetId, payroll, billing) => `${prefix}/timesheet/${timesheetId}/slideout?payroll=${payroll}&billing=${billing}`,
        slideoutStaff: (dispLocationId) => `${prefix}/timesheet/slideoutStaff/${dispLocationId}`,
        slideoutEquipment: (dispLocationId) => `${prefix}/timesheet/slideoutEquipment/${dispLocationId}`,
        byId: (timesheetId) => `${prefix}/timesheet/${timesheetId}`,
        byEmp: (timesheetId, empId) => `${prefix}/timesheet/${timesheetId}?empId=${empId}`,
        update: () => `${prefix}/timesheet/updateTimesheet`,
        createLinkedTimesheet: (parentId) => `${prefix}/timesheet/createLinkedTimesheet/${parentId}`,
        updateWorkOrderNumber: (id) => `${prefix}/timesheet/${id}/workOrderNumber`,
        undoClientCancel: () => `${prefix}/timesheet/undoClientCancel`,
        updateForeman: (id) => `${prefix}/timesheet/${id}/foreman`,
        updateRequestor: (id) => `${prefix}/timesheet/${id}/requestor`,
        setEmergency: () => `${prefix}/timesheet/setEmergency`,
        byEmployeeSearch: () => `${prefix}/timesheets/searchByEmployee`,
        timesheetDashboard: () => `${prefix}/timesheets/getDashboard`,
        audit: () => `${prefix}/timesheet/audit`,
        equipmentAudit: (timesheetId) => `${prefix}/timesheet/${timesheetId}/equipment/audit`,
        rollBackApproved: (timesheetId) => `${prefix}/timesheet/${timesheetId}/rollBackApproved`,
        rollBackExported: (timesheetId) => `${prefix}/timesheet/${timesheetId}/rollBackExported`,
    },
    timesheetReview: {
        byId: (reviewId) => `${prefix}/timesheetReview/${reviewId}`,
        update: () => `${prefix}/timesheetReview/updateTimesheetReview`,
        clientByTimesheetId: (timesheetId) => `${prefix}/timesheetReview/client/${timesheetId}`,
        employeesByTimesheetId: (timesheetId) => `${prefix}/timesheetReview/employee/${timesheetId}`,
        missingSignatureReasons: () => `${prefix}/timesheetReview/missingSignatureReasons`,
    },
    timesheetManagement: {
        search: () => `${prefix}/timesheetManagements/search`,
        export: () => `${prefix}/timesheetManagements/export`,
        download: (id) => `${prefix}/timesheetManagement/download/${id}`,
        excelExport: () => `${prefix}/timesheetManagements/excelExport`,
        accept: (timesheetId) => `${prefix}/timesheetManagement/accept/${timesheetId}`,
        modify: (timesheetId) => `${prefix}/timesheetManagement/modify/${timesheetId}`,
        reject: () => `${prefix}/timesheetManagement/reject`,
        softDelete: () => `${prefix}/timesheetManagement/softDelete`,
        timesheetsBelongingToJobs: (ids) => `${prefix}/timesheetManagement/timesheetsBelongingToJobs/${ids}`,
    },
    USStates: () => `${prefix}/usstates`,
    USCounties: {
        all: () => `${prefix}/usCounties`,
        groups: () => `${prefix}/usCounties/groups`,
        groupTypes: () => `${prefix}/usCounties/groupTypes`,
        byGroupId: (id) => `${prefix}/usCounties/group/${id}`,
        byStateId: (id) => `${prefix}/usCounties/byStateId/${id}`,
        group: () => `${prefix}/usCounties/group`,
    },
    typeAheads: {
        jobCities: (partialTextSearch) => `${prefix}/typeahead/getJobCities/${partialTextSearch}`,
        companies: () => `${prefix}/typeahead/getCompanies`,
        customers: () => `${prefix}/typeahead/getCustomers`,
        parentCustomers: () => `${prefix}/typeahead/getParentCustomers`,
        subcontractingCustomers: () => `${prefix}/typeahead/getSubcontractingCustomers`,
        billingCompanies: () => `${prefix}/typeahead/getBillingCompanies`,
        contracts: () => `${prefix}/typeahead/getContracts`,
        contractsSearch: (partialTextSearch) => `${prefix}/typeahead/getContractNumber/${partialTextSearch}`,
        contractsByCompany: (companyId) => `${prefix}/typeahead/getContractsByCompany/${companyId}`,
        companyChargeTypeGroups: (companyId) => `${prefix}/typeahead/getCompanyChargeTypeGroups/${companyId}`,
        billingChargeTypes: (timesheetId) => `${prefix}/typeahead/getBillingChargeTypes/${timesheetId}`,
        billingContacts: (companyId) => `${prefix}/typeahead/getBillingContacts/${companyId}`,
        dispatchLocations: () => `${prefix}/typeahead/getDispatchLocations`,
        payrollCompanies: () => `${prefix}/typeahead/getPayrollCompanies`,
        companyTypes: () => `${prefix}/typeahead/getCompanyTypes`,
        companyStatuses: () => `${prefix}/typeahead/getCompanyStatus`,
        contractStatuses: () => `${prefix}/typeahead/getContractStatus`,
        chargeTypeUnits: () => `${prefix}/typeahead/getChargeTypeUnits`,
        chargeTypes: () => `${prefix}/typeahead/getChargeTypes`,
        chargeTypeSearch: (partialTextSearch) => `${prefix}/typeahead/getChargeTypes/${partialTextSearch}`,
        chargeTypesBillable: () => `${prefix}/typeahead/getChargeTypesBillable`,
        chargeTypeApplicability: () => `${prefix}/typeahead/getChargeTypeApplicability`,
        chargeTypesNonBillable: () => `${prefix}/typeahead/getChargeTypesNonBillable`,
        contractUnionWorkTypes: () => `${prefix}/typeahead/getUnionWorkTypes`,
        employeeComplianceTypes: () => `${prefix}/typeahead/getEmployeeComplianceTypes`,
        employeeGroups: () => `${prefix}/typeahead/getEmployeeGroups`,
        employees: () => `${prefix}/typeahead/getEmployees`,
        equipmentComplianceTypes: () => `${prefix}/typeahead/getEquipmentComplianceTypes`,
        equipmentTypes: () => `${prefix}/typeahead/getEquipmentTypes`,
        equipmentTypeAFADTypes: () => `${prefix}/typeahead/getEquipmentTypeAFADTypes`,
        equipmentTypeStatuses: () => `${prefix}/typeahead/getEquipmentTypeStatuses`,
        locationTypes: () => `${prefix}/typeahead/getLocationTypes`,
        permissions: () => `${prefix}/typeahead/getPermissions`,
        pendingBillingTimesheets: () => `${prefix}/typeahead/getPendingBillingTimesheets`,
        pendingPayrollTimesheets: () => `${prefix}/typeahead/getPendingPayrollTimesheets`,
        roles: () => `${prefix}/typeahead/getRoles`,
        USStates: () => `${prefix}/typeahead/getUSStates`,
        crewSignatureTypes: () => `${prefix}/typeahead/getCrewSignatureTypes`,
        clientSignatureTypes: () => `${prefix}/typeahead/getClientSignatureTypes`,
        jobAssignmentCancellationTypes: () => `${prefix}/typeahead/getJobAssignmentCancellationTypes`,
        jobCancellationTypes: () => `${prefix}/typeahead/getJobCancellationTypes`,
        timesheetNumberSearch: (partialTextSearch) => `${prefix}/typeahead/getTimesheetNumber/${partialTextSearch}`,
        timesheetStatuses: () => `${prefix}/typeahead/getTimesheetStatuses`,
        timesheetDetailStatuses: () => `${prefix}/typeahead/getTimesheetDetailStatuses`,
        timesheetDetails: (timesheetId) => `${prefix}/typeahead/getTimesheetDetails/${timesheetId}`,
        payrollStatuses: () => `${prefix}/typeahead/getPayrollStatuses`,
        timesheetManagementStatuses: () => `${prefix}/typeahead/getTimesheetManagementStatuses`,
        timesheetBillingStatuses: () => `${prefix}/typeahead/getTimesheetBillingStatuses`,
        billingStatuses: () => `${prefix}/typeahead/getBillingStatuses`,
        billingChargeStatuses: () => `${prefix}/typeahead/getBillingChargeStatuses`,
        operationsCenters: () => `${prefix}/typeahead/getOperationsCenters`,
        subcontractors: (customerId) => `${prefix}/typeahead/getSubcontractors/${customerId}`,
        billingChargeFlags: () => `${prefix}/typeahead/getBillingChargeFlags`,
        billingCategoryFlags: () => `${prefix}/typeahead/getBillingCategoryFlags`,
        payrollChargeFlags: () => `${prefix}/typeahead/getPayrollChargeFlags`,
        payrollCategoryFlags: () => `${prefix}/typeahead/getPayrollCategoryFlags`,
        payrollChargeTypes: (timesheetId) => `${prefix}/typeahead/getPayrollChargeTypes/${timesheetId}`,
        invoiceTerms: () => `${prefix}/typeahead/getInvoiceTerms`,
        invoiceMapping: () => `${prefix}/typeahead/getInvoiceMapping`,
        invoiceStatuses: () => `${prefix}/typeahead/getInvoiceStatuses`,
        activityTaskStatuses: () => `${prefix}/typeahead/getActivityTaskStatuses`,
        activityTaskTypes: () => `${prefix}/typeahead/getActivityTaskTypes`,
        taskInteractionTypes: () => `${prefix}/typeahead/getTaskInteractionTypes`,
        chargeTypeGroups: () => `${prefix}/typeahead/getChargeTypeGroups`,
        quickBooksSalesAccounts: () => `${prefix}/typeahead/getQuickBooksInventoryItemSalesAccounts`,
        quickBooksInventoryItems: () => `${prefix}/typeahead/getQuickBooksInventoryItems`,
        serviceItems: () => `${prefix}/typeahead/getServiceItems`,
        
    },
    workOrders: {
        all: () => `${prefix}/workorders`,
    },
};

export const agGridConstants = {
    columnFilterTypes: {
        number: 'agNumberColumnFilter', // A Number Filter for number comparisons.
        text: 'agTextColumnFilter', // A Text Filter for string comparisons.
        date: 'agDateColumnFilter', // A Date Filter for date comparisons.
        set: 'agSetColumnFilter', // A Set Filter, influenced by how filters work in Microsoft Excel.This is an ag- Grid - Enterprise feature.
    },
};

export const ApplicationPermissions = {
    applicationusercompliance_create: 'applicationusercompliance.create',
    applicationusercompliance_edit: 'applicationusercompliance.edit',
    applicationusercompliance_delete: 'applicationusercompliance.delete',
    applicationusercompliance_view: 'applicationusercompliance.view',

    billing_create: 'billing.create',
    billing_edit: 'billing.edit',
    billing_delete: 'billing.delete',
    billing_view: 'billing.view',
    billing_void: 'billing.void',
    billingissues_view: 'billingissues.view',

    billingrate_create: 'billingrate.create',
    billingrate_edit: 'billingrate.edit',
    billingrate_delete: 'billingrate.delete',
    billingrate_view: 'billingrate.view',

    billing_review_view: 'billing_review.view',
    billing_review_accept: 'billing_review.accept',
    billing_review_reject: 'billing_review.reject',
    billing_review_invoice: 'billing_review.invoice',
    billing_tscontract_update: 'timesheet_contract.update',

    chargetype_create: 'chargetype.create',
    chargetype_edit: 'chargetype.edit',
    chargetype_delete: 'chargetype.delete',
    chargetype_view: 'chargetype.view',

    company_create: 'company.create',
    company_edit: 'company.edit',
    company_delete: 'company.delete',
    company_view: 'company.view',

    compliancetype_create: 'compliancetype.create',
    compliancetype_edit: 'compliancetype.edit',
    compliancetype_delete: 'compliancetype.delete',
    compliancetype_view: 'compliancetype.view',

    location_create: 'location.create',
    location_edit: 'location.edit',
    location_delete: 'location.delete',
    location_view: 'location.view',

    locationcontact_create: 'location_contact.create',
    locationcontact_edit: 'location_contact.edit',
    // locationcontact_delete: 'location.delete',
    locationcontact_view: 'location_contact.view',

    contactactivity_create: 'contactactivity.create',
    contactactivity_edit: 'contactactivity.edit',
    contactactivity_delete: 'contactactivity.delete',
    contactactivity_view: 'contactactivity.view',

    contract_create: 'contract.create',
    contract_edit: 'contract.edit',
    contract_delete: 'contract.delete',
    contract_view: 'contract.view',

    contractcompliance_create: 'contractcompliance.create',
    contractcompliance_edit: 'contractcompliance.edit',
    contractcompliance_delete: 'contractcompliance.delete',
    contractcompliance_view: 'contractcompliance.view',

    contractTemplate_create: 'contract_template.create',
    contractTemplate_edit: 'contract_template.edit',
    contractTemplate_view: 'contract_template.view',
    contractTemplate_delete: 'contract_template.delete',

    customer_create: 'customer.create',
    customer_edit: 'customer.edit',
    customer_contacts_edit: 'customer.contacts_edit',
    customer_delete: 'customer.delete',
    customer_view: 'customer.view',

    dispatch_create: 'dispatch.create',
    dispatch_edit: 'dispatch.edit',
    dispatch_delete: 'dispatch.delete',

    dispatch_view: 'dispatch.view',
    dispatch_view_all: 'dispatch.view_all',

    dispatch_review_view: 'dispatch_review.view',
    dipsatch_review_accept: 'dipsatch_review.accept',
    dispatch_review_reject: 'dispatch_review.reject',

    equipment_create: 'equipment.create',
    equipment_edit: 'equipment.edit',
    equipment_delete: 'equipment.delete',
    equipment_view: 'equipment.view',

    equipmentcompliance_create: 'equipmentcompliance.create',
    equipmentcompliance_edit: 'equipmentcompliance.edit',
    equipmentcompliance_delete: 'equipmentcompliance.delete',
    equipmentcompliance_view: 'equipmentcompliance.view',

    equipmentstatus_create: 'equipmentstatus.create',
    equipmentstatus_edit: 'equipmentstatus.edit',
    equipmentstatus_delete: 'equipmentstatus.delete',
    equipmentstatus_view: 'equipmentstatus.view',

    equipmenttype_create: 'equipmenttype.create',
    equipmenttype_edit: 'equipmenttype.edit',
    equipmenttype_delete: 'equipmenttype.delete',
    equipmenttype_view: 'equipmenttype.view',

    employee_create: 'employee.create',
    employee_edit: 'employee.edit',

    employee_delete: 'employee.delete',
    employee_view: 'employee.view',
    employee_onboarding: 'employee.onboard',
    employee_add: 'employee.add',
    employee_view_history: 'employee.history',

    employee_pay_view: 'employee_pay.view',

    employee_routing_address_edit: 'employee_routing_address.edit',
    employee_home_address_edit: 'employee_home_address.edit',
    employee_username_edit: 'employee_username.edit',
    employee_group_edit: 'employee_groups.edit',
    employee_name_edit: 'employee_name.edit',
    employee_payroll_company_edit: 'employee_payroll_company.edit',
    employee_dispatch_company_edit: 'employee_dispatch_company.edit',
    employee_number_edit: 'employee_number.edit',
    employee_phone_edit: 'employee_phone.edit',
    employee_notifications_edit: 'employee_notifications.edit',
    employee_importupdates_edit: 'employee_importupdates.edit',
    employee_accountstatus_edit: 'employee_accountstatus.edit',
    employee_password_edit: 'employee_password.edit',
    employee_exclude_dispatch: 'employee_exclude_dispatch.edit',

    employee_restriction_view: 'employee_restriction.view',
    employee_restriction_create: 'employee_restriction.create',
    employee_restriction_edit: 'employee_restriction.edit',
    employee_restriction_delete: 'employee_restriction.delete',

    group_create: 'group.create',
    group_edit: 'group.edit',
    group_delete: 'group.delete',
    group_view: 'group.view',

    job_create: 'job.create',
    job_edit: 'job.edit',
    job_delete: 'job.delete',
    job_view: 'job.view',

    payroll_create: 'payroll.create',
    payroll_edit: 'payroll.edit',
    payroll_delete: 'payroll.delete',
    payroll_view: 'payroll.view',

    payroll_review_view: 'payroll_review.view',
    payroll_review_accept: 'payroll_review.accept',
    payroll_review_reject: 'payroll_review.reject',
    payroll_review_export: 'payroll_review.export',

    self_dispatch_timesheet_create: 'selfdispatchtimesheet.create',
    self_dispatch_timesheet_view: 'selfdispatchtimesheet.view',
    nonFlagging_timesheet_view: 'nonflaggingtimesheet.view',
    nonFlagging_timesheet_create: 'nonflaggingtimesheet.create',

    timesheet_create: 'timesheet.create',
    timesheet_edit: 'timesheet.edit',
    timesheet_delete: 'timesheet.delete',
    timesheet_export: 'timesheet.export',
    timesheet_view: 'timesheet.view',
    timesheet_accept: 'timesheet.accept',
    nf_timesheet_reject: 'timesheet.reject',
    flagging_timesheet_reject: 'flagging_timesheet.reject',
    timesheet_dashboard: 'timesheet_dashboard.view',

    quickBooksInventoryItem_view: 'quickBooksInventoryItem.view',
    quickBooksInventoryItem_create: 'quickBooksInventoryItem.create',
    quickBooksInventoryItem_edit: 'quickBooksInventoryItem.edit',
    quickBooksInventoryItem_delete: 'quickBooksInventoryItem.delete',

    countyGroup_view: 'county_group.view',
    countyGroup_create: 'county_group.create',
    countyGroup_edit: 'county_group.edit',
    countyGroup_delete: 'county_group.delete',

    tenant_edit: 'tenant.edit',
    impersonation_toggle: 'impersonation.toggle',
    company_code_edit: 'company_code.edit',
};

export const Weekdays = [
    {
        id: 0, name: 'Sunday', abbreviation: 'Sun', abbreviationShort: 'S',
    },
    {
        id: 1, name: 'Monday', abbreviation: 'Mon', abbreviationShort: 'M',
    },
    {
        id: 2, name: 'Tuesday', abbreviation: 'Tue', abbreviationShort: 'T',
    },
    {
        id: 3, name: 'Wednesday', abbreviation: 'Wed', abbreviationShort: 'W',
    },
    {
        id: 4, name: 'Thursday', abbreviation: 'Thu', abbreviationShort: 'R',
    },
    {
        id: 5, name: 'Friday', abbreviation: 'Fri', abbreviationShort: 'F',
    },
    {
        id: 6, name: 'Saturday', abbreviation: 'Sat', abbreviationShort: 'S',
    },
];

export const SupportedFileExtensions = {
    image: ['.gif', '.jpg', '.jpeg', '.png', '.heic', '.webp'],
    document: ['.xlsx', '.xls', '.pdf', '.txt', '.doc', '.docx'],
};

export const SiteRoles = {
    Administrator: '6f176015-2b18-4d7a-896a-aa97c498ea66',
    RegionalSupport: '4488e0d6-0e54-4079-8b15-39195c23188e',
};

export const ServiceResponseResult = {
    Ok: 0,
    Error: 1,
    OkWithErrors: 2,
};

export const MomentTimeFormat = {
    TwentyFourHourNoLeading: 'H:mm',
    TwentyFourHourWithLeading: 'HH:mm',
    TwelveHourWithLeading: 'hh:mm',
    TwelveHourNoLeading: 'h:mm',
    AnteTwentyFourHour: 'H:mm A',
    AnteTwelveHour: 'h:mm A',
};

export const MomentDateFormat = {
    DateOnlyUTCHyphenated: 'YYYY-MM-DD',
    DateOnlyUTCSlashes: 'YYYY/MM/DD',
    MonthDayYearHyphensNoLeading: 'M-DD-YYYY',
    MonthDayYearHyphensLeading: 'MM-DD-YYYY',
    MonthDayYearSlashesNoLeading: 'M/DD/YYYY',
    MonthDayYearSlashesLeading: 'MM/DD/YYYY',
    DateTimeShortAnte: 'M/D/YY, h:mm A',
};

export const TimesheetReviewType = {
    Client: 1,
    Employee: 2,
};

export const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const MonthsShort = [
    'Jan',
    'Feb',
    'Ma',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

// 2022-08-11 - M. Nicol
// Used in conjunction with Util.js > l10n to get localized strings.
// Note: Strings are part of CommonContext Provider definition in Layout.js.
// Accessible through CommonContext component itself or this.context as defined
// by "static contextType = CommonContext;" in most components.
// Should be kept in sync with API.
export const LocalizationKeys = {
    billing: 'Billing',
    crewLead: 'CrewLead',
};

export const ContactTypes = {
    Contract: 1,
    Billing: 2,
    Foreman: 3,
    Supervisor: 4,
    Office: 5,
    ProjectManager: 6
};
